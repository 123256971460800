:root {
  --bg-main: #081818;
  --grad-blue: linear-gradient(180deg, #015eea 0%, #00c0fa 100%);
  --grad-yellow: linear-gradient(180deg, #facc22 0%, #f83600 100%);
  --grad-green: linear-gradient(180deg, #122825 0%, #2b5a4a 100%);
  --grad-green-light: linear-gradient(180deg, #f4ffaa 0%, #5cc039 100%);
  --grad-red: linear-gradient(180deg, #ffa176 0%, #ff372d 100%);
  --box-shadow-yellow: 0 0 5px 3px rgba(248, 186, 117, 0.75);
  --grad-green-transparent: linear-gradient(180deg, rgba(18,40,37,0.75) 0%, rgba(43,90,74,0.75) 100%);
  --main-green: #03a36e;
  --text-green: #10ab4a;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: 'Serithai-Regular';
  src: local('Serithai-Regular'),
    url('./assets/fonts/Serithai-Regular.ttf') format('truetype');
  font-weight: normal;
}

body {
  margin: 0;
  font-family: 'Serithai-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-main);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li {
  list-style: none;
}

button,
input,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

input {
  -webkit-appearance: none;
}

a {
  text-decoration: none;
}

.disabled-link {
  pointer-events: none;
}