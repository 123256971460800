.DesktopLogin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .DesktopLoginContainer {
        width: 500px;
        background: var(--grad-green);
        border-radius: 15px;
        padding-bottom: 20px;

        .DesktopLoginButtons {
            display: flex;
            color: #fff;
            height: 40px;
            font-size: 18px;
            font-weight: bold;

            button {
                width: 50%;
                background: var(--grad-green);
            }

            .Register {
                border-top-left-radius: 15px;
            }

            .Login {
                border-top-right-radius: 15px;
            }

            .ButtonActive {
                background: var(--grad-yellow);
            }
        }

        .FormToggle {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #fff;
            margin-top: 15px;
    
            span {
                font-size: 16px;
            }
    
            button {
                background: var(--grad-blue);
                padding: 5px 0;
                border-radius: 20px;
                width: 40%;
            }
        }
    }
}