.AwaitingData {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
}


.PaymentTitle {
    font-size: 16px;
}

.PaymentInfo {
    font-size: 14px;
}

.FormContainer {
    padding: 10px 0;

    form {

        .AmountContainer {
            display: flex;
            align-items: center;
            width: 100%;

            span {
                font-size: 14px;
                margin-right: 5px;
                flex: 1;

                @media (min-width: 901px) {
                    font-size: 16px;
                }
            }

            input {
                width: 100%;
                font-size: 14px;
                background: var(--grad-green);
                border-radius: 15px;
                color: #fff;
                text-indent: 10px;

                @media (min-width: 901px) {
                    font-size: 16px;
                }
            }

            .AmountError {
                border: 1px solid #f00;
            }
        }

        .DepositError {
            font-size: 14px;
            color: #f00;

            @media (min-width: 901px) {
                font-size: 16px;
            }
        }

        .AmountRequirements {
            span {
                font-size: 14px;
                margin-right: 5px;

                @media (min-width: 901px) {
                    font-size: 16px;
                }
            }
        }

        button {
            display: flex;
            width: 100%;
            justify-content: center;
            font-size: 14px;
            background: var(--grad-blue);
            padding: 5px;
            border-radius: 15px;
            margin-top: 5px;

            @media (min-width: 901px) {
                font-size: 16px;
            }
        }
    }
}