.AuthFormWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;

    .AuthForm {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background: var(--grad-green-transparent);
        width: 90%;
        padding: 20px;
        border-radius: 20px;

        .FormInputContainer {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;

            .FormIcon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                padding: 7px;
                background-color: var(--bg-main);
                border-radius: 50%;
                margin-right: 5px;

                .FormIconImage {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }

            .FormInput {
                font-size: 16px;
                background-color: var(--bg-main);
                border-radius: 15px;
                color: #fff;
                text-indent: 10px;
                width: 100%;
                height: 40px;
            }

            .FormInputError {
                border: 1px solid #f00;
            }

            .AuthFormPasswordToggle {
                position: absolute;
                z-index: 1;
                display: flex;
                right: 10px;

                .FormIconEyeImage {
                    height: 30px;
                    width: 30px;
                }
            }

            .AuthFormPasswordToggleError {
                right: 45px;
            }

            .ErrorImage {
                height: 30px;
                width: 30px;
                margin-left: 5px;
            }
        }

        .FormInputContainerUsername {
            margin-bottom: 10px;
        }
    }

    .DesktopAuthForm {
        background: none;
    }

    .AuthFormLoginButton {
        display: flex;
        justify-content: center;
        background: var(--grad-green);
        width: 40%;
        padding: 5px 0;
        border-radius: 20px;
        margin-top: 10px;

        span {
            color: #fff;
            font-size: 16px;
            font-weight: bold;
        }
    }
}