.GamesSection {
    width: calc(100% - 80px);
    overflow-x: hidden;
    padding: 10px 10px 85px 10px;

    @media (min-width: 991px) {
        width: calc(100% - 100px);
    }

    @media (min-width: 1301px) {
        margin: auto;
    }
}