.MobileLoginFormContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;

    .MobileLoginFormCloseButton {
        position: absolute;
        z-index: 10;
        top: 10px;
        right: 10px;
        height: 40px;
        width: 40px;
        background: var(--bg-main);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .MobileLoginFormCloseButtonIcon {
            height: 60%;
            width: 60%;
            object-fit: contain;
        }
    }

    .MobileLoginFormLogoContainer {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;

        .MobileLoginFormLogo {
            position: absolute;
            z-index: 1;
            top: -60px;
            height: 330px;
        }

        .MobileLoginFormLogoMain {
            z-index: 2;
        }
    }

    .FormToggle {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;

        span {
            font-size: 18px;
            margin-bottom: 5px;
        }

        button {
            background: var(--grad-blue);
            padding: 5px 0;
            border-radius: 20px;
            width: 40%;
        }
    }
}