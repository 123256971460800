.GameLabels {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
    padding-right: 10px;

    @media (min-width: 601px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 801px) {
        grid-template-columns: repeat(5, 1fr);
    }

    @media (min-width: 1301px) {
        grid-template-columns: repeat(6, 1fr);
    }

    button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #122825;
        height: 80px;
        border-radius: 10px;

        color: #fff;
        font-size: 13px;

        @media (min-width: 601px) {
            height: 100px;
        }

        .GameLabelImage {
            object-fit: contain;
            height: 50px;
            width: 80px;

            @media (min-width: 901px) {
                width: 100px;
            }
        }
    }
}