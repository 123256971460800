.GameControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    color: #fff;
    height: 40px;

    .GameControlsName {
        display: flex;
        align-items: center;
        height: 100%;

        .GameControlsNameLogo {
            height: 95%;
            object-fit: contain;
        }

        p {
            font-size: 14px;
            font-weight: bold;
            margin-left: 10px;
        }

    }

    .Buttons {
        display: flex;
        height: 100%;

        .Button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            width: 30px;
            padding: 5px;
            border-radius: 50%;
            margin-left: 10px;
        }

        .Favorite {
            background: var(--grad-green);
        }

        .Fullscreen {
            background: var(--grad-blue);
        }

        .CloseButtonGame {
            background: var(--grad-yellow);
        }

        .GameControlsIcon {
            font-size: 20px;
        }

    }
}

.GameControlsLandscape {
    flex-direction: column-reverse;
    height: 100%;
    width: 40px;
    justify-content: end;

    .GameControlsName {
        height: 40px;
        margin-bottom: 10px;
        .GameControlsNameLogo {
            width: 40px;
        }
    }

    .Buttons {
        flex-direction: column;

        .Button {
            margin-left: 0;
            margin-bottom: 10px;
        }
    }
}