.WithdrawForm {
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    input,
    select {
        width: 100%;
        font-size: 15px;
        background: var(--grad-green);
        border-radius: 15px;
        color: #fff;
        text-indent: 10px;
        margin: 5px 0;
        padding: 3px 0;

        &::placeholder {
            font-size: 15px;
            color: #fff;
        }

        @media (min-width: 901px) {
            font-size: 16px;
        }
    }

    select:focus {
        color: #000;
    }

    select option {
        color: #000;
    }

    .WithdrawFormError {
        font-size: 15px;
        color: #f00;
        margin-left: 10px;

        @media (min-width: 901px) {
            font-size: 16px;
        }
    }

    .WithdrawFormSelect {
        padding: 8px 0;
        border: none;
    }

    .WithdrawInputError {
        border: 1px solid #f00;
    }

    .AmountRequirements {
        span {
            font-size: 14px;
            margin-right: 5px;

            @media (min-width: 901px) {
                font-size: 16px;
            }
        }
    }

    button {
        display: flex;
        width: 100%;
        justify-content: center;
        font-size: 14px;
        background: var(--grad-yellow);
        padding: 5px;
        border-radius: 15px;
        margin-top: 5px;

        @media (min-width: 901px) {
            font-size: 16px;
        }
    }
}