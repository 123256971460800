.AuthHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 5px;

    .UserContainer {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .User {
            display: flex;
            align-items: center;
            background-color: var(--bg-main);
            border-radius: 15px;
            padding: 3px 10px;

            .UserImage {
                height: 20px;
                width: 20px;
                margin-right: 5px;
            }

            span {
                font-size: 12px;
                color: #fff;

                @media (min-width: 901px) {
                    font-size: 16px;
                }
            }
        }

        button {
            display: flex;
            align-items: center;
            background: var(--grad-green);
            padding: 2px 10px;
            border-radius: 15px;
            color: #fff;
            border: 1px solid #1c1c1c;
            font-size: 12px;
            transition: box-shadow .3s ease-in;
            margin-left: 5px;

            @media (min-width: 901px) {
                font-size: 16px;

                &:hover {
                    box-shadow: var(--box-shadow-yellow);
                }
            }

            img {
                height: 14px;
                width: 14px;
                object-fit: contain;
                margin-right: 5px;

                @media (min-width: 901px) {
                    height: 16px;
                    width: 16px;
                }
            }
        }

        .LogoutButton {
            padding: 3px 10px;

            @media (min-width: 901px) {
                padding: 6px 10px;
            }

            .LogoutIcon {
                font-size: 20px;
                color: #fff;
            }
        }
    }


    .BalanceContainer {
        display: flex;
        align-items: center;

        .Button {
            margin-right: 5px;
            color: #fff;
            font-size: 12px;
            font-weight: bold;
            padding: 2px 15px;
            border-radius: 15px;

            @media (min-width: 901px) {
                font-size: 14px;
                transition: box-shadow .3s ease-in;

                &:hover {
                    box-shadow: var(--box-shadow-yellow);
                }
            }
        }

        .ButtonHistory {
            background: var(--bg-main);
        }

        .ButtonActive {
            background: var(--grad-red);
        }

        .ButtonDeposit {
            background: var(--grad-blue);
        }

        .ButtonWithdraw {
            background: var(--grad-yellow);
        }

        .Balance {
            display: flex;
            align-items: center;
            background-color: var(--bg-main);
            border-radius: 15px;
            padding: 3px 10px;

            img {
                height: 20px;
                width: 20px;
                object-fit: contain;
                margin-right: 5px;
            }

            span {
                font-size: 12px;
                color: #fff;

                @media (min-width: 901px) {
                    font-size: 14px;
                }
            }

        }
    }

    .MobileHeaderButtons {
        display: flex;
        align-items: center;
        margin-top: 5px;

        .Button {
            margin-left: 5px;
            color: #fff;
            font-size: 12px;
            font-weight: bold;
            padding: 2px 10px;
            border-radius: 15px;
        }

        .ButtonHistory {
            background: var(--bg-main);
        }

        .ButtonActive {
            background: var(--grad-red);
        }
    }
}