.GamesList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    // margin-right: 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    @media (min-width: 601px) {
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
    }

    @media (min-width: 801px) {
        grid-template-columns: repeat(5, 1fr);
    }

    @media (min-width: 1301px) {
        grid-template-columns: repeat(6, 1fr);
    }
}