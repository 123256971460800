.Footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--grad-green);
    width: 100%;

    span {
        color: #fff;
        font-weight: bold;
        font-size: 16px;
        padding: 10px 0;
    }
}