.Header {
    position: sticky;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    background: var(--grad-green);

    .LogoButton {
        height: 70px;
        width: 70px;

        img {
            object-fit: contain;
            height: 100%;
            width: 100%;
        }
    }

    .ButtonsContainer {
        display: flex;
        align-items: center;
        color: #fff;

        button {
            margin: 3px;
            padding: 5px;
            min-width: 100px;
            max-height: 40px;
            border-radius: 20px;
            font-size: 14px;
            font-weight: bold;

            @media (min-width: 991px) {
                font-size: 15px;
                min-width: 120px;
            }
        }

        .Register {
            background: var(--grad-blue);
        }

        .Login {
            background: var(--grad-yellow);
        }
    }
}