.ProfileMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .MainBox {
        span {
            margin-right: 5px;
        }
    }
}