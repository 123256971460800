.News {
    width: 100%;
    background: var(--grad-green);

    @media (min-width: 991px) {
        width: 95%;
        border-radius: 50px;
        padding: 0 15px;
        margin: auto;
    }

    .Container {
        display: flex;
        align-items: center;
        padding: 5px 10px;

        .Announce {
            height: 35px;
            width: 35px;
            object-fit: contain;
        }

        .Wrapper {
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            width: 100%;
            overflow: hidden;

            .Text {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                white-space: nowrap;
                text-align: right;
            }
        }
    }
}