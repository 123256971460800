.MobileFooter {
    position: sticky;
    z-index: 2;
    bottom: 0;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 80px;

    .MobileButton {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 20%;

        .MobileFooterImg {
            height: 30px;
            margin-bottom: 5px;
        }

        span {
            background: linear-gradient(to top, #5cc039, #f4ffaa);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    .MobileButtonCenter {
        transform: translateY(-15px);

        .MobileFooterCenterContainer {
            display: flex;
            justify-content: center;
            align-items: center;

            .MobileFooterLogo {
                position: absolute;
                width: 45px;
            }

            .MobileFooterLogoCenter {
                height: 80px;
                width: 80px;
                margin-bottom: 2px;
                border-radius: 50%;
                animation: glowing 3s infinite;
            }
        }
    }

    .MobileFooterBackground {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
    }
}

@keyframes glowing {
    0% {
        box-shadow: 0 1px 5px 0px #97f94e;
    }

    50% {
        box-shadow: 0 1px 15px 0px #97f94e;
    }

    100% {
        box-shadow: 0 1px 5px 0px #97f94e;
    }
}