.Categories {
    position: sticky;
    top: 80px;
    height: calc(100vh - 160px);
    width: 80px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;

    @media only screen and (max-width: 768px) and (orientation: landscape) {
        height: calc(100vh - 80px);
    }

    @media (min-width: 901px) {
        height: calc(100vh - 80px);
    }

    @media (min-width: 991px) {
        width: 140px;
    }

    .Button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 65px;
        height: 65px;
        background: var(--grad-green);
        border-radius: 15px;
        margin-bottom: 8px;
        overflow: hidden;
        transition: all .3s ease-in;

        @media (min-width: 991px) {
            width: 120px;
            height: 120px;
        }

        img {
            height: 40px;
            width: 40px;

            @media (min-width: 991px) {
                height: 80px;
                width: 80px;
            }
        }

        span {
            color: #fff;
            font-size: 11px;
            font-weight: bold;

            @media (min-width: 991px) {
                font-size: 15px;
            }
        }
    }

    .Selected {
        background: var(--grad-red);
    }
}