.TransactionsButtonsHistory {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .TransactionsButtonsHistoryActive {
        box-shadow: inset 0px -2px 0px 0px #fff;
    }
}

.HistoryGame {
    margin: 10px 0;
    background: var(--grad-green);
    border-radius: 10px;
    padding: 10px;

    p {
        font-size: 14px;
    }

    .HistoryGameTitle {
        font-weight: bold;
        color: #10ab4a;
        font-size: 16px;
    }
}