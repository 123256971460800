.Slider {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 15px;
    opacity: 0;
    transition: .1s opacity ease-in .3s;

    &[data-visible='true'] {
        opacity: 1;
    }

    @media (max-width: 500px) {
        padding: 0 12px;
    }

    @media (max-width: 900px) {
        padding: 0 10px 10px 10px;
    }

    @media (min-width: 901px) {
        padding: 0 20px 20px 20px;
    }

    .BannerSlider {
        width: 100% !important;
        transition: .2s opacity ease;

        &.swiper-slide-next, &.swiper-slide-prev {
            opacity: .1;
        }
    }

    .Banner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        &[data-mobile='true'] {
            margin-top: 12px;
        }

        @media (max-width:650px) {
            display: block;
        }

        .BannerImage {
            width: 100%;
            height: auto;
            max-height: 257px;
            border-radius: 20px;

            @media (orientation:portrait) {
                max-height: unset;
            }
        }
    }
}