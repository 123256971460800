.Profile {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: var(--bg-main);

    @media (min-width: 901px) {
        z-index: 11;
        background-color: rgba(0, 0, 0, 0.75);
    }
}