.Preloader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;

    .Ring {
        position: absolute;
        height: 80px;
        width: 80px;
        background-color: var(--main-green);
        border-radius: 50%;
        opacity: 0.5;
    }

    .Logo {
        position: absolute;
        z-index: 1;
        height: 150px;
        width: 150px;
        object-fit: contain;
    }
}