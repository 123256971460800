.LanguageButton {
    display: block !important;
    height: 30px !important;
    width: 30px !important;
    min-width: 30px !important;
    border-radius: 50% !important;
    border: none !important;
    padding: 0 !important;
    background: var(--grad-blue) !important;

    @media (min-width: 901px) {
        height: 35px !important;
        width: 35px !important;
    }

    .LanguageButtonImage {
        height: 100% !important;
        width: 100% !important;
        object-fit: cover !important;
    }
}