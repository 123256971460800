.ButtonLine {
    position: fixed;
    z-index: 20;
    height: 80px;
    width: 80px;
    right: 5px;
    bottom: 85px;

    @media (min-width: 901px) {
        right: 20px;
        bottom: 20px;
    }

    .LineIcon {
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
}

.ButtonLineLanscape {
    bottom: 20px;
}