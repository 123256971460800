.CloseModal {
    position: fixed;
    z-index: 51;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.85);

    h4 {
        color: var(--text-green);
        font-size: 18px;
        margin-bottom: 10px;
    }

    div {
        display: flex;
        color: #fff;
        font-size: 16px;

        button {
            padding: 5px 20px;
            border-radius: 15px;
            margin: 0 5px;
        }

        .Cancel {
            background: var(--grad-blue);
        }

        .Confirm {
            background: var(--grad-yellow);
        }
    }
}