.MainView {
    position: relative;
    border-radius: 15px;
    color: #fff;
    padding: 10px 30px;
    width: 95%;
    max-height: calc(100vh - 180px);
    overflow-y: auto;

    @media (min-width: 661px) {
        width: 70%;
        padding-top: 50px;
    }

    @media (min-width: 801px) {
        width: 50%;
    }

    @media (min-width: 1001px) {
        width: 40%;
        max-width: 500px;
        background: var(--grad-green);
        padding: 20px 30px;
    }
}

.ProfileTitle {
    font-size: 20px;
    color: var(--text-green);
    font-weight: bold;

    @media (min-width: 901px) {
        color: #fff;
        font-size: 24px;
    }
}

.ProfileContainer {
    width: 100%;
    background-color: #000;
    border-radius: 15px;
    padding: 10px 15px;

    @media (min-width: 901px) {
        background-color: var(--bg-main);
        padding: 10px 20px;
    }
}

.ProfileButtonBlue {
    padding: 5px 20px;
    background: var(--grad-blue);
    border-radius: 15px;
    margin-top: 15px;
    font-weight: bold;
}

.CloseButton {
    display: none;

    @media (min-width: 901px) {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--grad-yellow);
        border-radius: 50%;
        padding: 5px;

        &:hover {
            box-shadow: var(--box-shadow);
        }
    }

    img {
        height: 20px;
        width: 20px;
        object-fit: contain;
    }
}