.LogoutModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;

    h4 {
        font-size: 20px;
    }

    div {
        display: flex;

        button {
            margin: 10px;
            padding: 5px 30px;
            border-radius: 15px;
            transition: box-shadow .3s ease-in;

            @media (min-width: 901px) {
                &:hover {
                    box-shadow: var(--box-shadow-yellow);
                }
            }
        }

        .Cancel {
            background: var(--grad-blue);
        }

        .Confirm {
            background: var(--grad-yellow);
        }
    }
}