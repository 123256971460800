.ProfilePassword {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;


    div {
        span {
            font-size: 16px;
            margin-right: 5px;
        }
    }

    .InputContainer {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            margin-right: 5px;
            flex: 1;

            @media (min-width: 901px) {
                font-size: 16px;
            }
        }

        .FormInput {
            flex: 2;
            font-size: 14px;
            background: var(--grad-green);
            border-radius: 15px;
            color: #fff;
            text-indent: 10px;

            @media (min-width: 901px) {
                font-size: 16px;
            }
        }

        .FormInputError {
            border: 1px solid #f00;
        }

        .AuthFormPasswordToggle {
            cursor: pointer;
            position: absolute;
            z-index: 1;
            display: flex;
            right: 10px;

            .FormIconEyeImage {
                height: 25px;
                width: 25px;
            }
        }
    }

    .InputContainerError {
        margin-bottom: 0;
    }

    .ErrorMessage {
        width: 100%;
        text-align: end;
        color: #f00;
        font-size: 14px;
        margin-bottom: 10px;

        @media (min-width: 901px) {
            font-size: 16px;
        }
    }

    .ProfilePasswordButtons {
        display: flex;
        width: 100%;
        justify-content: center;
        font-size: 14px;

        @media (min-width: 901px) {
            font-size: 16px;
        }

        .ProfilePasswordButton {
            padding: 5px 30px;
            margin-right: 5px;
            height: 40px;
        }

        .ProfilePasswordButtonCancel {
            padding: 5px 30px;
            background: var(--grad-yellow);
            margin-left: 5px;
            height: 40px;
        }
    }
}