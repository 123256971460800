.Game {
    position: relative;
    height: 100%;
    width: 100%;
    border: 1px solid #022d1e;
    border-radius: 5px;
    overflow: hidden;

    .ImageWrapper {
        position: relative;
        height: 0;
        width: 100%;
        padding-bottom: 75%; /* 4:3 Aspect Ratio */
    }

    .PlaceholderContainer,
    .GameImageContainer {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
        }
    }

    .GameName {
        position: absolute;
        z-index: 1;
        color: #fff;
        width: 100%;
        left: 0;
        bottom: 5px;
        font-size: 11px;

        @media (min-width: 601px) {
            font-size: 13px;
        }

        @media (min-width: 901px) {
            font-size: 14px;
        }
    }

    .GameFs {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 35px !important;
        width: 25px !important;
        object-fit: contain;

        @media (min-width: 1001px) {
            height: 50px !important;
            width: 30px !important;
        }
    }

    .GameImage {
        opacity: 0;
        transform: scale(0);
        animation: fadeIn 0.3s 0.3s ease-in forwards;
    }

    .GameHover {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.75);
        border-radius: 5px;

        .PlayButton {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            height: 35px;
            width: 35px;
            background: var(--grad-red);
            border-radius: 50%;

            @media (min-width: 1001px) {
                height: 45px;
                width: 45px;
            }

            .PlayIcon {
                height: 30px;
                width: 30px;
                padding-left: 5px;
            }
        }

        .PlayButtonLoading {
            background: none;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}