.TransactionsButtons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;

    button {
        padding: 5px 10px;
        border-radius: 10px;
        min-width: 120px;
        background: var(--grad-green);
    }

    .Active {
        background: var(--grad-green-light);
    }
}

.LoadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 175px;
}

.Transaction {
    margin: 10px 0;
    background: var(--grad-green);
    border-radius: 10px;
    padding: 10px;

    .TransactionDetails {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .TransactionType {
            font-size: 15px;
            font-weight: bold;
            color: #10ab4a;
        }

        .TransactionId {
            font-size: 13px;
        }

        .TransactionTime {
            font-size: 12px;
        }

        .TransactionCash {
            font-size: 15px;
        }
    }
}

.TransactionsEmpty {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 175px;
}