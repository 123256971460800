.iOS_Overlay {
    position: absolute;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 3000px;
    overflow: auto;

    .FingerWrapper {
        height: 3000px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.75);

        .Finger {
            height: 80px;
            object-fit: contain;
            position: fixed;
            top: 50%;
            right: 15%;
            color: var(--white);
            font-size: 70px;
            animation: moveUpDown 3s linear infinite forwards;
        }
    }
}

.GameWindow {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--bg-main);
    height: 100vh;
    width: 100vw;
}

.GameWindowLanscape {
    display: flex;
    flex-direction: row;
}

@keyframes moveUpDown {
    0% {
        transform: translateY(-100%) rotate(-45deg);
    }

    50% {
        transform: translateY(100%) rotate(-45deg);
    }

    100% {
        transform: translateY(-100%) rotate(-45deg);
    }
}